/* eslint-disable prefer-destructuring */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-sequences */
/* eslint-disable react/jsx-fragments */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { injectIntl, intlShape } from 'react-intl';
import { Select } from 'lec-ui';

import messages from './panelFiltersMessages';
import './PanelFilters.scss';

const PanelFiltersContainer = (props) => {
  const {
    intl,
    filterSegment,
    filterComponent,
    filterPeriod,
    filterScheduledCourse,
    idSegment,
    idComponent,
    idScheduled,
    setIdSegment,
    setIdComponent,
    setPeriod,
    setPlan,
    handleShowChangePeriodModal,
    setIdScheduled,
    idSegmentSelected,
    idComponentSelected,
    periodSelected,
    idScheduledSelected,
    disabled,
    hasPlanCustomChanges,
    role,
    filterTeacher,
    idTeacher,
    setIdTeacher,
    idTeacherSelected,
    disabledPeriod,
    years,
    selectedYear,
    setSelectedYear,
    disabledYear,
    schools,
    selectedSchool,
    setSelectedSchool,
    disabledSchool,
  } = props;

  const [idSegmentSelectedState, setIdSegmentSelectedState] = useState(idSegmentSelected);
  const [idComponentSelectedState, setIdComponentSelectedState] = useState(idComponentSelected);

  const [idTeacherSelectedState, setIdTeacherSelectedState] = useState(idTeacherSelected);

  useEffect(() => {
    if (idSegmentSelected && idComponentSelected) {
      setIdSegment(idSegmentSelected);
      setIdComponent(idComponentSelected);
    }
  }, [idSegmentSelected, idComponentSelected]);

  useEffect(() => {
    if (idScheduledSelected) {
      setIdScheduled(idScheduledSelected);
    }
  }, [idScheduledSelected]);

  useEffect(() => {
    if (idSegment) {
      setIdSegmentSelectedState(idSegment);
    }
  }, [idSegment]);

  useEffect(() => {
    if (idComponent) {
      setIdComponentSelectedState(idComponent);
    }
  }, [idComponent]);

  useEffect(() => {
    if (idTeacher) {
      setIdTeacherSelectedState(idTeacher);
    }
  }, [idTeacher]);

  function handleChangeFilterSegment(filterSegmentId) {
    setIdSegment(filterSegmentId || null);
    setIdSegmentSelectedState(filterSegmentId || null);
    setIdComponent(null);
  }

  function handleChangeFilterComponent(event) {
    const selectedOption = event.target.options[event.target.selectedIndex];
    const value = selectedOption.value || null;
    const idPlan = selectedOption.getAttribute('data-id-plan') || {};
    setIdComponent(value);
    setIdComponentSelectedState(value);
    setPlan(idPlan);
  }

  function handleChangeFilterPeriod(filterPeriodId) {
    if (hasPlanCustomChanges) {
      // setShowChangePeriodModal(true);
      handleShowChangePeriodModal(filterPeriodId);
    } else {
      setPeriod(filterPeriodId);
    }
  }

  function handleChangeFilterScheduledCourse(filterScheduledCourseId) {
    setIdScheduled(filterScheduledCourseId);
  }

  function handleChangeFilterTeacher(filterTeacherId) {
    setIdTeacher(filterTeacherId || null);
    setIdTeacherSelectedState(filterTeacherId || null);
  }

  function handleChangeSelectedYear(selectedYearId) {
    setSelectedYear(selectedYearId);
  }

  function handleChangeSelectedSchool(selectedSchoolId) {
    setSelectedSchool(selectedSchoolId);
  }

  return (
    <div className="panel-container">
      <h1 className="page-title mr-3">{intl.formatMessage(messages.title)}</h1>
      {role !== 4 && (
        <Fragment>
          <Select
            containerclassname="mt-0 mr-3"
            placeholder={intl.formatMessage(messages.years)}
            onChange={(e) => handleChangeSelectedYear(e.target.value)}
            options={years || []}
            value={selectedYear || ''}
            disabled={disabled || disabledYear}
          />
          {role === 2 && schools && schools.length > 1 && (
            <Select
              containerclassname="mt-0 mr-3"
              placeholder={intl.formatMessage(messages.schools)}
              onChange={(e) => handleChangeSelectedSchool(e.target.value)}
              options={schools || []}
              value={selectedSchool || ''}
              disabled={disabled || disabledSchool}
            />
          )}
          {filterTeacher && filterTeacher.length > 0 && (
            <Select
              containerclassname="mt-0 mr-3"
              // placeholder={intl.formatMessage(messages.allTeachers)}
              onChange={(e) => handleChangeFilterTeacher(e.target.value)}
              options={[
                { value: '', label: intl.formatMessage(messages.allTeachers) },
                ...filterTeacher,
              ]}
              value={idTeacherSelectedState || ''}
              nullable="true"
            />
          )}
          <Select
            containerclassname="mt-0 mr-3"
            // placeholder={intl.formatMessage(messages.stageYear)}
            onChange={(e) => handleChangeFilterSegment(e.target.value)}
            options={[
              { value: '', label: intl.formatMessage(messages.stageYear) },
              ...filterSegment,
            ]}
            value={idSegmentSelectedState || ''}
            nullable="true"
            disabled={disabled}
          />
          <Select
            containerclassname="mt-0 mr-3"
            // placeholder={intl.formatMessage(messages.component)}
            onChange={(e) => handleChangeFilterComponent(e)}
            options={[
              { value: '', label: intl.formatMessage(messages.component) },
              ...filterComponent,
            ]}
            disabled={!idSegmentSelectedState || disabled}
            value={idComponentSelectedState || ''}
            nullable
          />
          {filterPeriod.length > 1 && (
            <Fragment>
              <Select
                containerclassname="mt-0 mr-3"
                onChange={(e) => handleChangeFilterPeriod(e.target.value)}
                options={filterPeriod || []}
                value={periodSelected || ''}
                disabled={disabledPeriod}
              />
            </Fragment>
          )}
          {filterScheduledCourse.length > 0 && (
            <Fragment>
              <Select
                containerclassname="mt-0 mr-3"
                placeholder={intl.formatMessage(messages.courses)}
                onChange={(e) => handleChangeFilterScheduledCourse(e.target.value)}
                options={filterScheduledCourse || []}
                // disabled={!idSegment || disabled}
                value={idScheduled || ''}
                nullable="true"
              />
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  );
};

PanelFiltersContainer.propTypes = {
  intl: intlShape,
  idSegment: PropTypes.any,
  idComponent: PropTypes.any,
  idScheduled: PropTypes.any,
  setIdSegment: PropTypes.func,
  setIdComponent: PropTypes.func,
  setPeriod: PropTypes.any,
  setPlan: PropTypes.any,
  handleShowChangePeriodModal: PropTypes.object,
  setIdScheduled: PropTypes.any,
  filterSegment: PropTypes.arrayOf(PropTypes.any),
  filterComponent: PropTypes.arrayOf(PropTypes.any),
  filterPeriod: PropTypes.arrayOf(PropTypes.any),
  filterScheduledCourse: PropTypes.arrayOf(PropTypes.any),
  idSegmentSelected: PropTypes.string,
  idComponentSelected: PropTypes.string,
  periodSelected: PropTypes.string,
  idScheduledSelected: PropTypes.string,
  disabled: PropTypes.bool,
  hasPlanCustomChanges: PropTypes.bool,
  role: PropTypes.number,
  filterTeacher: PropTypes.arrayOf(PropTypes.any),
  idTeacher: PropTypes.any,
  setIdTeacher: PropTypes.func,
  idTeacherSelected: PropTypes.string,
  disabledPeriod: PropTypes.bool,
  years: PropTypes.arrayOf(PropTypes.any),
  selectedYear: PropTypes.string,
  setSelectedYear: PropTypes.func,
  disabledYear: PropTypes.bool,
  schools: PropTypes.arrayOf(PropTypes.any),
  selectedSchool: PropTypes.string,
  setSelectedSchool: PropTypes.func,
  disabledSchool: PropTypes.bool,
};

PanelFiltersContainer.defaultProps = {
  intl: [],
  idSegment: '',
  idComponent: '',
  idScheduled: '',
  setIdSegment: {},
  setIdComponent: {},
  setPeriod: {},
  setPlan: () => {},
  handleShowChangePeriodModal: {},
  setIdScheduled: {},
  filterSegment: [],
  filterComponent: [],
  filterPeriod: [],
  filterScheduledCourse: [],
  idSegmentSelected: null,
  idComponentSelected: null,
  periodSelected: 'bimonthly',
  idScheduledSelected: null,
  disabled: false,
  hasPlanCustomChanges: false,
  role: 3,
  filterTeacher: [],
  idTeacher: '',
  setIdTeacher: {},
  idTeacherSelected: null,
  disabledPeriod: false,
  years: [],
  selectedYear: '',
  setSelectedYear: {},
  disabledYear: false,
  schools: [],
  selectedSchool: '',
  setSelectedSchool: {},
  disabledSchool: false,
};

export default compose(injectIntl)(PanelFiltersContainer);
