/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-danger */
/* eslint-disable no-sequences */
/* eslint-disable react/jsx-fragments */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { injectIntl, intlShape } from 'react-intl';

import { Row, Col } from 'react-bootstrap';
import { Modal } from 'lec-ui';

import messages from './createPlanMessages';
import './PlanCards.scss';

const LessonCard = (props) => {
  const { intl, lesson, period, number, moveLesson, removeLesson, restoreLesson, createClass } =
    props;
  // const [numberClass, setNumberClass] = useState(true);
  const [activeTab, setActiveTab] = useState(false);
  const [showModalRemoveClass, setShowModalRemoveClass] = useState(false);
  const [showModalRestoreClass, setShowModalRestoreClass] = useState(false);
  const [idLessonSelected, setIdLessonSelected] = useState(null);

  // const [auxLesson, setAuxLesson] = useState([]);

  function changeActiveTab() {
    setActiveTab(!activeTab);
  }

  function handleClassMove(direction, idLesson, blockPosition) {
    moveLesson(direction, idLesson, blockPosition);
  }

  function handleModalRemoveClass(idLesson) {
    setShowModalRemoveClass(true);
    setIdLessonSelected(idLesson);
  }
  function handleRemoveClass() {
    removeLesson(idLessonSelected);
    setShowModalRemoveClass(false);
  }
  function hideModalRemoveClass() {
    setShowModalRemoveClass(false);
  }

  function handleModalRestoreClass(idLesson) {
    setShowModalRestoreClass(true);
    setIdLessonSelected(idLesson);
  }
  function handleRestoreClass() {
    restoreLesson(idLessonSelected);
    setShowModalRestoreClass(false);
  }
  function hideModalRestoreClass() {
    setShowModalRestoreClass(false);
  }

  function handleCreateClass(where, value, type, periodDate) {
    createClass(where, value, type, periodDate);
  }

  useEffect(() => {
    if (number === 0) {
      setActiveTab(!activeTab);
    }
  }, []);

  // useEffect(() => {
  //   if (lesson) {
  //     if (typeof lesson === 'object') {
  //       setAuxLesson([lesson]);
  //     } else {
  //       setAuxLesson(lesson);
  //     }
  //   }
  // }, [lesson]);

  let label1;
  let label2;
  let label3;
  let abilities;
  let procedures;
  let classes;

  return (
    <Fragment>
      <button
        type="button"
        className={`planCardHead ${activeTab ? 'active' : ''}`}
        onClick={() => changeActiveTab()}
        tabIndex={number}
      >
        <span>
          {number + 1}
          {'º '}
          {period === 'bimonthly' || period === null
            ? intl.formatMessage(messages.bimonthly)
            : intl.formatMessage(messages.quarterly)}
        </span>
        <i className="icon pe-7s-angle-down" />
      </button>

      {/* {lesson.length === 0 && (
        <a
          className={`btn-createClassPeriod planCardBody ${activeTab ? 'active' : ''} `}
          onClick={() => handleCreateClass('period', number, 'adding', number)}
        >
          <i className="icon pe-7s-plus" />
          <div>{intl.formatMessage(messages.createClass)}</div>
        </a>
      )} */}
      {lesson.map((card, idx) => (
        <div
          key={`idx-${idx}`}
          className={`planCardBody ${activeTab ? 'active' : ''} ${
            card.removed === '1' ? 'removed' : ''
          }`}
        >
          <Row>
            <Col>
              <Row>
                <Col xl={4} className="configBlock">
                  <div className="configData">
                    {
                      ((label1 = card.values.findIndex((el) => el.variable === 'label1')),
                      label1 && card.values[label1] && card.values[label1].showPlanno === '1' && (
                        <span
                          dangerouslySetInnerHTML={{ __html: card.values[label1].value || '' }}
                        />
                      ))
                    }
                    {
                      ((label2 = card.values.findIndex((el) => el.variable === 'label2')),
                      label2 && card.values[label2] && card.values[label2].showPlanno === '1' && (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ` - ${card.values[label2].value}` || '',
                          }}
                        />
                      ))
                    }
                    <br />
                    {
                      ((label3 = card.values.findIndex((el) => el.variable === 'label3')),
                      label3 && card.values[label3] && card.values[label3].showPlanno === '1' && (
                        <div
                          className="descriptionBlock"
                          dangerouslySetInnerHTML={{
                            __html: card.values[label3].value.replace(/;/g, ';<br/>') || '',
                          }}
                        />
                      ))
                    }
                    <br />
                    {
                      ((classes = card.values.findIndex((el) => el.variable === 'classes')),
                      classes && card.values[classes].showPlanno === '1' && (
                        <div className="classesBlock labelBlock">
                          <b>{card.values[classes].label}</b>
                          <div className="label">{card.values[classes].value}</div>
                        </div>
                      ))
                    }
                  </div>
                </Col>
                <Col className="configBlock">
                  {
                    ((abilities = card.values.findIndex((el) => el.variable === 'abilities')),
                    abilities &&
                      card.values[abilities] &&
                      card.values[abilities].showPlanno === '1' && (
                        <div>
                          <b>{card.values[abilities].label}</b>
                          <div className="labelBlock">
                            {card.values[abilities].value.map((el, idx3) => (
                              <div
                                key={`idx3-${idx3}`}
                                className="label"
                                dangerouslySetInnerHTML={{ __html: el }}
                              />
                            ))}
                          </div>
                        </div>
                      ))
                  }
                </Col>
                <Col>
                  {
                    ((procedures = card.values.findIndex((el) => el.variable === 'procedures')),
                    procedures &&
                      card.values[procedures] &&
                      card.values[procedures].showPlanno === '1' && (
                        <div>
                          <b>{card.values[procedures].label}</b>
                          <div className="labelBlock">
                            {card.values[procedures].value.map((el, idx3) => (
                              <div
                                key={`idx3-${idx3}`}
                                className="label"
                                dangerouslySetInnerHTML={{ __html: el }}
                              />
                            ))}
                          </div>
                        </div>
                      ))
                  }
                </Col>
              </Row>
            </Col>

            <div className="card-menu">
              <i className="fas fa-ellipsis-v" />
              <div className="cm-floating">
                {card.removed !== '1' && (
                  <span>
                    <a
                      className="cmf-view"
                      onClick={() => handleCreateClass('card', card.id, 'adding', number)}
                    >
                      <i className="icon pe-7s-plus" />
                      <div>{intl.formatMessage(messages.createClass)}</div>
                    </a>
                    <a
                      className="cmf-view"
                      onClick={() => handleCreateClass('card', card.id, 'editing', number)}
                    >
                      <i className="icon pe-7s-pen" />
                      <div>{intl.formatMessage(messages.editClass)}</div>
                    </a>
                    {!card.firstLesson && (
                      <a
                        className="cmf-view"
                        onClick={() =>
                          handleClassMove(
                            'a',
                            card.id,
                            (card.firstLessonBlock && 'f') || (card.lastLessonBlock && 'l'),
                          )
                        }
                      >
                        <i className="icon pe-7s-up-arrow" />
                        <div>{intl.formatMessage(messages.moveUp)}</div>
                      </a>
                    )}
                    {!card.lastLesson && (
                      <a
                        className="cmf-view"
                        onClick={() =>
                          handleClassMove(
                            'b',
                            card.id,
                            (card.firstLessonBlock && 'f') || (card.lastLessonBlock && 'l'),
                          )
                        }
                      >
                        <i className="icon pe-7s-bottom-arrow" />
                        <div>{intl.formatMessage(messages.moveDown)}</div>
                      </a>
                    )}
                    <a className="cmf-view" onClick={() => handleModalRemoveClass(card.id)}>
                      <i className="icon pe-7s-close-circle" />
                      <div>{intl.formatMessage(messages.remove)}</div>
                    </a>
                  </span>
                )}
                {card.removed === '1' && (
                  <a className="cmf-view" onClick={() => handleModalRestoreClass(card.id)}>
                    <i className="icon pe-7s-refresh-2" />
                    <div>{intl.formatMessage(messages.restore)}</div>
                  </a>
                )}
              </div>
            </div>
          </Row>
        </div>
      ))}
      <Modal
        show={showModalRemoveClass}
        icon="pe-7s-trash"
        onClose={() => hideModalRemoveClass()}
        title={intl.formatMessage(messages.removeClass)}
        message={intl.formatMessage(messages.confirmRemoveClass)}
        buttons={[
          {
            label: intl.formatMessage(messages.cancel),
            onClick: () => hideModalRemoveClass(),
          },
          {
            label: intl.formatMessage(messages.continue),
            onClick: () => handleRemoveClass(),
            primary: true,
          },
        ]}
      />
      <Modal
        show={showModalRestoreClass}
        icon="pe-7s-trash"
        onClose={() => hideModalRestoreClass()}
        title={intl.formatMessage(messages.restoreClass)}
        message={intl.formatMessage(messages.confirmRestoreClass)}
        buttons={[
          {
            label: intl.formatMessage(messages.cancel),
            onClick: () => hideModalRestoreClass(),
          },
          {
            label: intl.formatMessage(messages.continue),
            onClick: () => handleRestoreClass(),
            primary: true,
          },
        ]}
      />
    </Fragment>
  );
};

LessonCard.propTypes = {
  intl: intlShape,
  lesson: PropTypes.arrayOf(PropTypes.any),
  period: PropTypes.string,
  number: PropTypes.number,
  moveLesson: PropTypes.func.isRequired,
  removeLesson: PropTypes.func.isRequired,
  restoreLesson: PropTypes.func.isRequired,
  createClass: PropTypes.func.isRequired,
};

LessonCard.defaultProps = {
  intl: [],
  lesson: [],
  period: 'bimonthly',
  number: 0,
};

export default compose(injectIntl)(LessonCard);
