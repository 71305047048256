/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-danger */
/* eslint-disable no-sequences */
/* eslint-disable react/jsx-fragments */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { injectIntl, intlShape } from 'react-intl';

import { Row, Col } from 'react-bootstrap';

import messages from './viewPlanMessages';
import './PlanCards.scss';

const LessonCard = (props) => {
  const { intl, lesson, period, number } = props;
  const [activeTab, setActiveTab] = useState(false);

  // const [auxLesson, setAuxLesson] = useState([]);

  function changeActiveTab() {
    setActiveTab(!activeTab);
  }

  useEffect(() => {
    if (number === 0) {
      setActiveTab(!activeTab);
    }
  }, []);

  // useEffect(() => {
  //   if (lesson) {
  //     if (typeof lesson === 'object') {
  //       setAuxLesson([lesson]);
  //     } else {
  //       setAuxLesson(lesson);
  //     }
  //   }
  // }, [lesson]);

  let label1;
  let label2;
  let label3;
  let abilities;
  let procedures;
  let classes;

  return (
    <Fragment>
      <button
        type="button"
        className={`planCardHead ${activeTab ? 'active' : ''}`}
        onClick={() => changeActiveTab()}
        tabIndex={number}
      >
        <span>
          {number + 1}
          {'º '}
          {period === 'bimonthly' || period === null
            ? intl.formatMessage(messages.bimonthly)
            : intl.formatMessage(messages.quarterly)}
        </span>
        <i className="icon pe-7s-angle-down" />
      </button>

      {lesson.map((card, idx) => (
        <div
          key={`idx-${idx}`}
          className={`planCardBody ${activeTab ? 'active' : ''} ${
            card.removed === '1' ? 'removed' : ''
          }`}
        >
          <Row>
            <Col>
              <Row>
                <Col xl={4} className="configBlock">
                  <div className="configData">
                    {
                      ((label1 = card.values.findIndex((el) => el.variable === 'label1')),
                      label1 && card.values[label1] && card.values[label1].showPlanno === '1' && (
                        <span
                          dangerouslySetInnerHTML={{ __html: card.values[label1].value || '' }}
                        />
                      ))
                    }
                    {
                      ((label2 = card.values.findIndex((el) => el.variable === 'label2')),
                      label2 && card.values[label2] && card.values[label2].showPlanno === '1' && (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ` - ${card.values[label2].value}` || '',
                          }}
                        />
                      ))
                    }
                    <br />
                    {
                      ((label3 = card.values.findIndex((el) => el.variable === 'label3')),
                      label3 && card.values[label3] && card.values[label3].showPlanno === '1' && (
                        <div
                          className="descriptionBlock"
                          dangerouslySetInnerHTML={{
                            __html: card.values[label3].value.replace(/;/g, ';<br/>') || '',
                          }}
                        />
                      ))
                    }
                    <br />
                    {
                      ((classes = card.values.findIndex((el) => el.variable === 'classes')),
                      classes && card.values[classes].showPlanno === '1' && (
                        <div className="classesBlock labelBlock">
                          <b>{card.values[classes].label}</b>
                          <div className="label">{card.values[classes].value}</div>
                        </div>
                      ))
                    }
                  </div>
                </Col>
                <Col className="configBlock">
                  {
                    ((abilities = card.values.findIndex((el) => el.variable === 'abilities')),
                    abilities &&
                      card.values[abilities] &&
                      card.values[abilities].showPlanno === '1' && (
                        <div>
                          <b>{card.values[abilities].label}</b>
                          <div className="labelBlock">
                            {card.values[abilities].value.map((el, idx3) => (
                              <div
                                key={`idx3-${idx3}`}
                                className="label"
                                dangerouslySetInnerHTML={{ __html: el }}
                              />
                            ))}
                          </div>
                        </div>
                      ))
                  }
                </Col>
                <Col>
                  {
                    ((procedures = card.values.findIndex((el) => el.variable === 'procedures')),
                    procedures &&
                      card.values[procedures] &&
                      card.values[procedures].showPlanno === '1' && (
                        <div>
                          <b>{card.values[procedures].label}</b>
                          <div className="labelBlock">
                            {card.values[procedures].value.map((el, idx3) => (
                              <div
                                key={`idx3-${idx3}`}
                                className="label"
                                dangerouslySetInnerHTML={{ __html: el }}
                              />
                            ))}
                          </div>
                        </div>
                      ))
                  }
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      ))}
    </Fragment>
  );
};

LessonCard.propTypes = {
  intl: intlShape,
  lesson: PropTypes.arrayOf(PropTypes.any),
  period: PropTypes.string,
  number: PropTypes.number,
};

LessonCard.defaultProps = {
  intl: [],
  lesson: [],
  period: 'bimonthly',
  number: 0,
};

export default compose(injectIntl)(LessonCard);
