/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-sequences */
/* eslint-disable react/jsx-fragments */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';

import { Row, Col } from 'react-bootstrap';

import { Loader, Card, CardBody, Text, View, Title, Icon } from 'lec-ui';
import { first, find } from 'lodash';

import * as act from 'app/actions';
import * as myClassActions from './myClassActions';

import AddGroupButton from './components/AddGroupButton';
import AddClassModal from './components/AddClassModal';
import AddGroupModal from './components/AddGroupModal';
import RemoveGroupModal from './components/RemoveGroupModal';
import UploadConfirmModal from './components/UploadConfirmModal';
import YearsTabs from './components/YearsTabs';
import Menu from './components/Menu';
// import Schedule from './components/Schedule';
// import MoreInfo from './components/MoreInfo';

import messages from './myClassMessages';

const MyClassContainer = (props) => {
  const {
    intl,
    history,
    isLoading,
    getSchoolList,
    getCoursesStudent,
    getCourses,
    error,
    errCode,
    errorEva,
    joinGroup,
    addGroup,
    schools,
    courses,
    role,
    years,
    removeGroup,
  } = props;
  const [loading, setLoading] = useState(false);
  const [isOpen] = useState(false);
  const [areRemoving, setAreRemoving] = useState(false);
  const [reset, setReset] = useState(false);
  const [showAddGroupModal, setShowAddGroupModal] = useState(false);
  const [addClassModal, setAddClassModal] = useState(false);
  const [uploadConfirm, setUploadConfirm] = useState(false);
  const [showManualAddGroup] = useState(true);
  const [showAddGroup] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [voucher, setVoucher] = useState('');
  const [groupName, setGroupName] = useState('');
  const [messageError, setMessageError] = useState('');
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [handleRemove, setHandleRemove] = useState(() => null);
  const [isStudent] = useState(role === 4);
  const [limit] = useState(6);
  // const [showQtd, setShowQtd] = useState(2);
  const [year, setYear] = useState(new Date().getFullYear());

  const prevSchools = useRef();
  const prevError = useRef();
  const prevIsOpen = useRef();
  const prevAreRemoving = useRef();

  async function handleFetchData() {
    setLoading(true);

    await getSchoolList();
    if (isStudent) {
      await getCoursesStudent();
    } else {
      await getCourses();
    }
    setLoading(false);
    setReset(false);
  }

  function handleChangeSelectedSchool(school) {
    setSelectedSchool(school);
  }

  function handleChangeInput(e) {
    if (isStudent) {
      setVoucher(e.target.value);
    } else {
      setGroupName(e.target.value);
    }
  }

  function handleCloseModal() {
    setShowAddGroupModal(false);
  }

  function handleConfirmModal() {
    if (error || errorEva) {
      setReset(true);
    }
    if (isStudent) {
      joinGroup({ voucher }).then(() => {
        if (!error) {
          handleCloseModal();
          handleFetchData();
        }
      });
    } else {
      addGroup({
        groupName,
        idSchool: schools[0] ? selectedSchool.idSchool : undefined,
      }).then(() => {
        if (!error) {
          handleCloseModal();
          handleFetchData();
        }
      });
    }
  }

  function handleManuallyUpload() {
    setAddClassModal(false);
    setShowAddGroupModal(true);
  }

  function handleCompleteUpload(updateMessage) {
    setUploadConfirm(true);
    setMessageError(
      updateMessage.length === 0 ||
        (updateMessage.length > 0 && updateMessage[0].status === 3 && updateMessage[0].message),
    );
    handleFetchData();
  }

  async function handleCloseConfirm() {
    setUploadConfirm(false);
    setAddClassModal(false);
  }

  function handleChangeYear(yearSelected) {
    setYear(yearSelected);
  }

  function handleOpenSubscription() {
    if (isStudent) {
      setShowAddGroupModal(true);
      return;
    }

    if (!showManualAddGroup) {
      setShowAddGroupModal(true);
      setAddClassModal(false);
    } else {
      setAddClassModal(true);
    }
  }

  async function handleOnRemove(course) {
    setAreRemoving(true);
    await removeGroup({
      idCourse: course.id,
    });
    setAreRemoving(false);
    handleFetchData();
  }

  function handleClickCard(course) {
    history.push(`/my-class/${course}`);
  }

  useEffect(() => {
    handleFetchData();
  }, []);

  useEffect(() => {
    prevSchools.current = schools;
    prevError.current = error;
    prevIsOpen.current = isOpen;
    prevAreRemoving.current = areRemoving;

    if (prevSchools.current.length !== schools.length || (schools.length > 0 && !selectedSchool)) {
      const school = first(schools);
      setSelectedSchool(school);
    }
    if (prevError.current !== error && error) {
      setHasError(true);
    }
    if (
      (prevIsOpen.current !== isOpen && !isOpen) ||
      (prevAreRemoving.current !== areRemoving && !areRemoving)
    ) {
      handleFetchData();
    }
  }, [schools, error, isOpen, areRemoving]);

  const resolvedCourses = isStudent
    ? courses
    : courses.reduce((prevElem, elem) => {
        let currentCourse = [{ ...elem, showSchool: elem.showSchool === 'Sim' }];
        if (elem.idSchools && elem.idSchools.length > 1) {
          currentCourse = [
            ...elem.idSchools.map((x) => ({
              ...elem,
              idSchools: [x],
              showSchool: elem.showSchool === 'Sim',
            })),
          ];
        }

        return [...prevElem, ...currentCourse];
      }, []);

  const filteredResolvedCourses = resolvedCourses.filter((c) => !year || Number(c.year) === year);

  const goBack = () => {
    history.push('/');
  };

  return (
    <Fragment>
      <Row>
        <Col>
          <div className="cp-container mb-5">
            <div>
              <h3 className="cp-title page-title">{intl.formatMessage(messages.title)}</h3>
            </div>
            {showAddGroup && (
              <div className="block-button-add d-flex">
                <button type="button" onClick={() => goBack()} className="btn-back">
                  {intl.formatMessage(messages.back)}
                </button>
                <AddGroupButton
                  onClick={handleOpenSubscription}
                  isStudent={isStudent}
                  intl={intl}
                />
              </div>
            )}
          </div>
        </Col>
      </Row>
      <Loader show={isLoading || loading} />

      <YearsTabs
        onlyYears
        years={years}
        pages={Math.ceil(filteredResolvedCourses.length / limit)}
        onChangeYear={handleChangeYear}
      >
        {({ page }) => (
          <Row className="mb-5">
            {resolvedCourses && courses.length === 0 && (
              <Text>{intl.formatMessage(messages.noData)}</Text>
            )}

            {filteredResolvedCourses.slice((page - 1) * limit, page * limit).map((course) => {
              let courseSchool = null;

              if (isStudent) {
                courseSchool = find(schools, (school) => school.idSchool === course.idSchool);
              } else if (course.showSchool && course.idSchools.length > 0) {
                courseSchool = find(
                  schools,
                  (school) => school.idSchool === first(course.idSchools),
                );
              }

              return (
                <View key={course.id} className="col-12 col-sm-6">
                  <Card className="card-reports" style={{ height: '94%' }}>
                    {!isStudent && <Menu onRemove={() => handleOnRemove(course)} />}
                    <CardBody
                      className="text-center"
                      onClick={() => {
                        !isStudent && handleClickCard(course.id);
                      }}
                      style={!isStudent ? { cursor: 'pointer' } : { cursor: 'initial' }}
                    >
                      <Icon className="card-icon pe-7s-settings mb-3" />
                      <Title h="2" type="card" className="mb-2">
                        {course.name}
                      </Title>
                      <Text className="card-description">
                        {isStudent ? (
                          course.fullTeacherName
                        ) : (
                          <Fragment>
                            <div className="" style={isStudent ? '' : { cursor: 'pointer' }}>
                              {intl.formatMessage(messages.students)}
                              {`: ${course.totalStudents}`}
                            </div>
                          </Fragment>
                        )}
                      </Text>
                      {courseSchool && (
                        <Text
                          className="my-class-schools-name mb-3"
                          style={{ textAlign: 'center' }}
                        >
                          {courseSchool.Name}
                        </Text>
                      )}
                      {/* {course && course.schedules && (
                        <Fragment>
                          <Schedule
                            courseId={course.id}
                            schedules={course.schedules || []}
                            showQtd={showQtd}
                            intl={intl}
                          />
                          {course.schedules.length > 3 && (
                            <MoreInfo
                              intl={intl}
                              onClick={() => setShowQtd(course.schedules.length)}
                            />
                          )}
                        </Fragment>
                      )} */}
                    </CardBody>
                  </Card>
                </View>
              );
            })}
          </Row>
        )}
      </YearsTabs>

      <RemoveGroupModal
        isOpen={areRemoving}
        hasError={hasError}
        errCode={errCode}
        handleClose={() => setAreRemoving(false)}
        handleConfirm={() => {
          handleRemove().then(() => {
            !hasError && setAreRemoving(false) && setHandleRemove(() => null);
          });
        }}
        handleTimeout={() => setHasError(false)}
      />
      <AddClassModal
        show={addClassModal}
        onClose={() => setAddClassModal(false)}
        onManuallyUpload={handleManuallyUpload}
        onCompleteUpload={(updateMessage) => handleCompleteUpload(updateMessage)}
        schools={schools || []}
        selectedSchool={selectedSchool}
        handleChangeSelect={handleChangeSelectedSchool}
      />
      <UploadConfirmModal
        show={uploadConfirm}
        onClose={handleCloseConfirm}
        messageError={messageError}
      />
      <AddGroupModal
        isOpen={showAddGroupModal}
        hasError={reset}
        errCode={errCode}
        schools={schools || []}
        selectedSchool={selectedSchool}
        groupName={groupName}
        voucher={voucher}
        handleClose={handleCloseModal}
        handleConfirm={handleConfirmModal}
        handleChangeSelect={handleChangeSelectedSchool}
        handleChangeInput={handleChangeInput}
        handleTimeout={() => setHasError(false)}
        isStudent={isStudent}
      />
    </Fragment>
  );
};

MyClassContainer.propTypes = {
  intl: intlShape,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  getSchoolList: PropTypes.func.isRequired,
  getCoursesStudent: PropTypes.func.isRequired,
  getCourses: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  errorEva: PropTypes.string,
  errCode: PropTypes.string,
  joinGroup: PropTypes.func.isRequired,
  addGroup: PropTypes.func.isRequired,
  schools: PropTypes.array.isRequired,
  courses: PropTypes.array.isRequired,
  role: PropTypes.string,
  years: PropTypes.array.isRequired,
  removeGroup: PropTypes.func.isRequired,
};

MyClassContainer.defaultProps = {
  intl: [],
  errorEva: '',
  errCode: '',
  role: null,
};

const mapStateToProps = ({ myClass, panel, app }) => ({
  ...myClass,
  ...panel,
  user: app.user,
  role: app.role,
});

export default compose(
  withRouter,
  injectIntl,
  connect(
    mapStateToProps,
    act.promisify(
      { ...myClassActions },
      {
        include: [
          'getCourses',
          'getCoursesStudent',
          'getSchoolList',
          'addGroup',
          'joinGroup',
          'removeGroup',
          'addStudent',
          'editStudent',
        ],
      },
    ),
  ),
)(MyClassContainer);
