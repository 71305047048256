/* eslint-disable consistent-return */
/* eslint-disable max-lines */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-fragments */
import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import { Loader, Button } from 'lec-ui';
import { Row, Col } from 'react-bootstrap';
import queryString from 'query-string';
import { api } from 'app/api';

import * as act from 'app/actions';
import * as classConfigurationActions from './classConfigurationActions';
import messages from './classConfigurationMessages';

import LessonPlanning from './LessonPlanning';
import LessonProposal from './LessonProposal';

import './ClassConfiguration.scss';

import AddNewLessonModal from './AddNewLessonModal';

import OnlineClassCard from './OnlineClassCard';
import SimplificaCard from './SimplificaCard';
import UploadFileCard from './UploadFileCard';
import StudentsFilesCard from './StudentsFilesCard';
import MessageBoxGroupChat from './MessageBoxGroupChat';

const ClassConfiguration = (props) => {
  const {
    intl,
    user,
    location,
    isLoading,
    getScheduledLessonData,
    manageScheduledLessonData,
    lessonData,
    editScheduledLessonResources,
    deleteScheduledLessonResources,
    changeScheduledLessonVisibility,
    history,
    getScheduledLessonChat,
    addScheduledLessonChatMessage,
    canSendMessages,
    conversationsList,
    messagesList,
    downloadLessonResourseFile,
    addScheduledLesson,
    errorAddScheduledLesson,
    successAddScheduledLesson,
    getScheduledPlanCoursesHours,
    scheduledPlanCoursesHours,
    mediaFile,
    viewLessonResourseFile,
    resetLessonData,
  } = props;
  const params = queryString.parse(location.search.replace('?', ''));

  // const [lessonPlanningButton, setLessonPlanningButton] = useState(
  //   !params.lessonIndex || parseInt(params.lessonIndex, 10) < 0,
  // );
  // const [lessonProposalButton, setLessonProposalButton] = useState(
  //   params.lessonIndex >= 0 && parseInt(params.lessonIndex, 10) >= 0
  //     ? parseInt(params.lessonIndex, 10)
  //     : null,
  // );

  const [lessonPlanningButton, setLessonPlanningButton] = useState(true);
  const [lessonProposalButton, setLessonProposalButton] = useState(null);

  const [materialLabel, setMaterialLabel] = useState('planning');
  const [proposalLabel, setProposalLabel] = useState('');

  // PLANNING CONTENT
  const [planningText, setPlanningText] = useState('');
  const [prevPlanningText, setPrevPlanningText] = useState('');
  const [planningId, setPlanningId] = useState('');
  const [planningResources, setPlanningResources] = useState([]);
  const [inputURLPlanning, setInputURLPlanning] = useState('');

  // PROPOSAL CONTENT
  const [proposalText, setProposalText] = useState('');
  const [prevProposalText, setPrevProposalText] = useState('');
  const [proposalId, setProposalId] = useState('');
  const [onlineClassContent, setOnlineClassContent] = useState({});
  const [simplificaContent, setSimplificaContent] = useState([]);
  const [filesContent, setFilesContent] = useState([]);
  const [studentsFilesContent, setStudentsFilesContent] = useState([]);
  const [inputURLProposal, setInputURLProposal] = useState('');

  const [toggleStudentView, setToggleStudentView] = useState(false);

  const [showAddExtraLessonModal, setShowAddExtraLessonModal] = useState(false);

  const [loading, setLoading] = useState(false);

  function hasHttp(obj) {
    const values = Object.values(obj);

    return values
      .map((value) => {
        if (
          typeof value === 'string' &&
          (value.includes('http://') || value.includes('https://'))
        ) {
          return true;
        }
        return false;
      })
      .some((value) => value === true);
  }

  async function callLessonData() {
    setLoading(true);
    await getScheduledLessonData({
      idLesson: params.idLesson,
      idPlanScheduled: params.scheduled,
    });
    setLoading(false);
  }

  async function handleResources(resource) {
    let id = null;
    const { action } = resource;

    if (lessonProposalButton !== null) {
      id = proposalId;
    } else {
      id = planningId;
    }

    if (resource.resourceType === 'file') {
      if (resource.files.length > 0) {
        const formData = new FormData();
        formData.append('resourceType', resource.resourceType);
        resource.files.forEach((file) => {
          formData.append('files[]', file);
        });
        formData.append('idLessonConfiguration', parseInt(id, 10));

        await api
          .post('/teach/addScheduledLessonResources.php', formData)
          .then(() => callLessonData(), Promise.resolve())
          .catch((err) => {
            console.error('erro no upload', err.response.data);
          });
      }

      if (lessonProposalButton !== null) {
        if (inputURLProposal !== '') {
          if (inputURLProposal !== resource.link) {
            await editScheduledLessonResources({
              idResource: resource.idResource,
              link: resource.link,
            });

            callLessonData();
          }
        } else {
          const formData = new FormData();
          formData.append('link', resource.link);
          formData.append('resourceType', resource.resourceType);
          formData.append('idLessonConfiguration', parseInt(id, 10));

          await api
            .post('/teach/addScheduledLessonResources.php', formData)
            .then(() => callLessonData(), Promise.resolve())
            .catch((err) => {
              console.error('erro no upload', err.response.data);
            });
        }
      } else if (inputURLPlanning !== '') {
        if (inputURLPlanning !== resource.link) {
          await editScheduledLessonResources({
            idResource: resource.idResource,
            link: resource.link,
          });

          callLessonData();
        }
      } else {
        const formData = new FormData();
        formData.append('link', resource.link);
        formData.append('resourceType', resource.resourceType);
        formData.append('idLessonConfiguration', parseInt(id, 10));

        await api
          .post('/teach/addScheduledLessonResources.php', formData)
          .then(() => callLessonData(), Promise.resolve())
          .catch((err) => {
            console.error('erro no upload', err.response.data);
          });
      }
    } else {
      if (action === 'add') {
        const formData = new FormData();
        formData.append('link', resource.link);
        formData.append('resourceType', resource.resourceType);
        formData.append('idLessonConfiguration', parseInt(id, 10));

        return api
          .post('/teach/addScheduledLessonResources.php', formData)
          .then(() => callLessonData(), Promise.resolve())
          .catch((err) => {
            console.error('erro no upload', err.response.data);
          });
      }
      if (action === 'edit') {
        await editScheduledLessonResources({
          idResource: resource.idResource,
          link: resource.link,
        });

        callLessonData();
      }
    }

    return Promise.resolve();
  }

  async function handleRemoveResources(idResource) {
    await deleteScheduledLessonResources({
      idResource,
    });

    callLessonData();
  }

  function compareStates(el, el2) {
    return el !== el2;

    // return el.replace(/<[^>]*>/g, '') !== el2.replace(/<[^>]*>/g, '');
  }

  async function handleManageTextContent() {
    if (lessonPlanningButton && compareStates(prevPlanningText, planningText)) {
      await manageScheduledLessonData({
        idLessonConfiguration: planningId,
        text: planningText,
      });

      setPrevPlanningText(planningText);
      callLessonData();
    }

    if (lessonProposalButton >= 0 && compareStates(prevProposalText, proposalText)) {
      await manageScheduledLessonData({
        idLessonConfiguration: proposalId,
        text: proposalText,
      });

      setPrevProposalText(proposalText);
      callLessonData();
    }
  }

  function handleChangeProposalResourcesContent(index) {
    if (index == null) index = 0;
    if (Object.keys(lessonData.proposal[index].resources).length > 0) {
      if (lessonData.proposal[index].resources.online_class) {
        setOnlineClassContent(lessonData.proposal[index].resources.online_class[0]);
      } else {
        setOnlineClassContent({});
      }

      if (lessonData.proposal[index].resources.simplifica) {
        setSimplificaContent(lessonData.proposal[index].resources.simplifica);
      } else {
        setSimplificaContent([]);
      }

      if (
        lessonData.proposal[index].resources.files &&
        lessonData.proposal[index].resources.files.myFiles
      ) {
        setFilesContent(lessonData.proposal[index].resources.files.myFiles);

        lessonData.proposal[index].resources.files.myFiles.forEach((file) => {
          if (hasHttp(file)) {
            setInputURLProposal(file.resource);
          }
        });
      } else {
        setFilesContent([]);
      }

      if (
        lessonData.proposal[index].resources.files &&
        lessonData.proposal[index].resources.files.studentsLessonFiles
      ) {
        setStudentsFilesContent(lessonData.proposal[index].resources.files.studentsLessonFiles);
      } else {
        setStudentsFilesContent([]);
      }
    } else {
      setOnlineClassContent({});
      setSimplificaContent({});
      setFilesContent([]);
      setStudentsFilesContent([]);
    }
  }

  async function changeButtonStatus(buttonType, index, label) {
    // handleManageTextContent();
    if (buttonType === 'planning') {
      setLessonPlanningButton(true);
      setLessonProposalButton(null);
      setMaterialLabel('planning');
      setProposalLabel('');

      const searchParams = new URLSearchParams(location.search);
      searchParams.set('lessonIndex', null);

      history.push(`${location.pathname}?${searchParams.toString()}`);
    } else {
      setLessonPlanningButton(false);
      setLessonProposalButton(index);
      setMaterialLabel('proposal');
      setProposalLabel(label);

      await getScheduledLessonChat({
        idLessonConfiguration: lessonData.proposal[index].id,
      });

      handleChangeProposalResourcesContent(index);

      setProposalText(lessonData.proposal[index].text);
      setPrevProposalText(lessonData.proposal[index].text);
      setProposalId(lessonData.proposal[index].id);
      // setToggleStudentView(lessonData.visible); // FROM API

      const searchParams = new URLSearchParams(location.search);
      searchParams.set('lessonIndex', index);

      history.push(`${location.pathname}?${searchParams.toString()}`);
    }
  }

  function handleToggleStudentView() {
    setToggleStudentView(!toggleStudentView);
    changeScheduledLessonVisibility({
      idPlanScheduled: params.scheduled,
      idLesson: params.idLesson,
      visible: !toggleStudentView,
    });
  }

  async function handleSaveAddLesson(newLesson) {
    await addScheduledLesson(newLesson);
    setShowAddExtraLessonModal(false);
    callLessonData();
  }

  useEffect(async () => {
    callLessonData();
    await getScheduledPlanCoursesHours({
      idPlanScheduled: params.scheduled,
    });
  }, []);

  useEffect(() => {
    if (lessonData.planning) {
      setPlanningText(lessonData.planning.text);
      setPrevPlanningText(lessonData.planning.text);
      setPlanningId(lessonData.planning.id);
      if (lessonData.planning.resources.files) {
        setPlanningResources(lessonData.planning.resources.files.myFiles);
        lessonData.planning.resources.files.myFiles.forEach((file) => {
          if (hasHttp(file)) {
            setInputURLPlanning(file.resource);
          }
        });
      } else {
        setPlanningResources([]);
      }
    }
    if (lessonData.proposal) {
      handleChangeProposalResourcesContent(lessonProposalButton);

      setProposalText(lessonData.proposal[lessonProposalButton || 0].text);
      setPrevProposalText(lessonData.proposal[lessonProposalButton || 0].text);
      setProposalId(lessonData.proposal[lessonProposalButton || 0].id);
      setToggleStudentView(lessonData.visible); // FROM API
    }
  }, [lessonData]);

  useEffect(() => {
    const lessonIndex = parseInt(params.lessonIndex, 10);

    if (
      lessonIndex >= 0 &&
      lessonData &&
      lessonData.proposal &&
      lessonData.proposal.length > 0 &&
      lessonData.proposal.length >= lessonIndex
    ) {
      setLessonPlanningButton(false);
      setLessonProposalButton(lessonIndex);

      console.log('lessonIndex', lessonIndex);
      console.log('lessonData.proposal[lessonIndex]', lessonData.proposal[lessonIndex]);

      const newLabel = lessonData.proposal[lessonIndex].label;
      setMaterialLabel('proposal');
      setProposalLabel(newLabel);
      changeButtonStatus('proposal', lessonIndex, newLabel);
    } else {
      setLessonPlanningButton(true);
      setLessonProposalButton(null);
      setMaterialLabel('planning');
      setProposalLabel('');
    }

    return () => {
      setLessonPlanningButton(true);
      setLessonProposalButton(null);
      setMaterialLabel('planning');
      setProposalLabel('');
    };
  }, [lessonData, params.lessonIndex]);

  async function handleSendMessage(message, userSelected) {
    if (lessonData.proposal && lessonData.proposal.length > 0) {
      await addScheduledLessonChatMessage({
        idLessonConfiguration: lessonData.proposal[lessonProposalButton || 0].id,
        idUser: userSelected.id,
        message,
      });

      await getScheduledLessonChat({
        idLessonConfiguration: lessonData.proposal[lessonProposalButton || 0].id,
      });
    }
  }

  function goBack() {
    // reset before leaving
    setLessonPlanningButton(true);
    setLessonProposalButton(null);
    setMaterialLabel('planning');
    setProposalLabel('');

    // Reset lessonData
    resetLessonData();

    history.push(
      `/plan-lessons?idPlan=${params.idPlan}&planSaved=${params.planSaved}&segment=${params.segment}&component=${params.component}&period=${params.period}&scheduled=${params.scheduled}`,
    );
  }

  useEffect(() => {
    if (lessonProposalButton !== null) {
      const interval = setInterval(() => {
        getScheduledLessonChat({
          idLessonConfiguration: lessonData.proposal[lessonProposalButton || 0].id,
        });
      }, 60000);
      return () => clearInterval(interval);
    }
  }, [lessonProposalButton]);

  function handleAddExtraLessonModal() {
    setShowAddExtraLessonModal(!showAddExtraLessonModal);
  }

  function handleDownloadFile(fileID, filename) {
    downloadLessonResourseFile({
      idResource: fileID,
      filename,
    });
  }

  function handleChangeProposalText(text) {
    setProposalText(text);
  }

  function handleChangePlanningText(text) {
    setPlanningText(text);
  }

  async function handleViewFile(fileID, type) {
    await viewLessonResourseFile({
      idResource: fileID,
    });
  }

  console.log('toggleStudentView', toggleStudentView);

  return (
    <Fragment>
      <Row>
        <Loader show={isLoading || loading} />
        <Col>
          <div className="date-container">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className="date-block">{lessonData.courseName}</div>
              <span>{intl.formatMessage(messages.title)}</span>
            </div>
            <button
              type="button"
              onClick={() => goBack()}
              className="btn btn-light"
              style={{ width: '130px', minWidth: 'inherit' }}
            >
              {intl.formatMessage(messages.back)}
            </button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12} lg={7} xl={7} className="mb-0 mb-md-5">
          <div className="configure-content">
            <Row>
              <Col lg="9">
                <div
                  style={{ fontWeight: 'bold' }}
                  dangerouslySetInnerHTML={{
                    __html: `${`${lessonData.label1 || ''} - ${lessonData.label2 || ''}`}`,
                  }}
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${lessonData.label3 || ''}`,
                  }}
                />
              </Col>
              <Col lg="3" className="studentToggle">
                <div>{intl.formatMessage(messages.studentView)}: </div>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={toggleStudentView}
                    onChange={handleToggleStudentView}
                  />
                  <span className="slider" />
                  <i className="fa-light fa-book-open-reader" />
                </label>
              </Col>
            </Row>
          </div>
          <div className="configure-tabs">
            {lessonData.planning && (
              <button
                type="button"
                onClick={() => changeButtonStatus('planning')}
                className={`${lessonPlanningButton ? 'active' : ''} btn-planning mr-2`}
              >
                {intl.formatMessage(messages.classPlanning)}
              </button>
            )}
            <div className="d-flex flex-wrap lessonBtnGroup">
              {lessonData.proposal &&
                lessonData.proposal.map((el, idx) => (
                  <button
                    key={idx}
                    type="button"
                    onClick={() => changeButtonStatus('proposal', idx, el.label)}
                    className={`${lessonProposalButton === idx ? 'active' : ''} btn-proposal`}
                  >
                    {intl.formatMessage(messages.class)} {el.label}
                    {el.hasChatMessages && <i className="ti-comments-smiley" />}
                  </button>
                ))}
              <button
                type="button"
                onClick={() => handleAddExtraLessonModal()}
                className="btn-proposal btn-new-lesson"
              >
                {intl.formatMessage(messages.newLesson)}
                <i className="ti-plus" />
              </button>
            </div>
          </div>
          <div style={{ marginTop: '15px' }}>
            {lessonData.planning && lessonPlanningButton && (
              <LessonPlanning
                isLoading={isLoading}
                handlePlanningText={(text) => handleChangePlanningText(text)}
                text={planningText}
                id={lessonData.planning.id}
              />
            )}
            {lessonData.proposal &&
              lessonData.proposal.map(
                (el, idx) =>
                  lessonProposalButton === idx && (
                    <LessonProposal
                      isLoading={isLoading}
                      handleProposalText={(text) => handleChangeProposalText(text)}
                      text={el.text}
                      id={el.id}
                      key={idx}
                      active={lessonProposalButton === idx}
                    />
                  ),
              )}
          </div>

          <div className="configureButtons mt-3">
            <Button onClick={() => handleManageTextContent()}>
              {intl.formatMessage(messages.save)}
            </Button>
          </div>
        </Col>
        <Col xs={12} md={12} lg={5} xl={5}>
          <div className="title-configure">
            {materialLabel === 'planning'
              ? intl.formatMessage(messages.planningMaterial)
              : `${intl.formatMessage(messages.materialOfTheDay)} ${proposalLabel}`}
          </div>
          <div className="resources-container">
            <div className="resources-block mb-3">
              {lessonProposalButton !== null && (
                <Fragment>
                  <OnlineClassCard
                    handleSaveOnlineClassLink={handleResources}
                    content={onlineClassContent}
                    handleRemoveUploadFileLink={handleRemoveResources}
                  />
                  <SimplificaCard
                    handleSaveSimplificaLink={handleResources}
                    content={simplificaContent}
                    handleRemoveUploadFileLink={handleRemoveResources}
                    idCourse={lessonData.idCourse}
                  />
                  {studentsFilesContent.length > 0 && (
                    <StudentsFilesCard
                      handleDownloadFile={handleDownloadFile}
                      content={studentsFilesContent}
                      handleViewFile={handleViewFile}
                      mediaFile={mediaFile}
                    />
                  )}
                </Fragment>
              )}
              <UploadFileCard
                handleSaveUploadFileLink={handleResources}
                handleDownloadFileLink={handleDownloadFile}
                handleRemoveUploadFileLink={handleRemoveResources}
                content={lessonProposalButton !== null ? filesContent : planningResources}
                mediaFile={mediaFile}
                handleViewFile={handleViewFile}
              />
            </div>
            {lessonProposalButton !== null && (
              <MessageBoxGroupChat
                sendMessage={(message, userSelected) => handleSendMessage(message, userSelected)}
                canSendMessages={canSendMessages}
                conversationsList={conversationsList}
                messagesList={messagesList}
                courseName={lessonData.courseName}
                daySelected={`${proposalLabel}`}
                userName={`${user.firstName} ${user.lastName}`}
              />
            )}
          </div>
          <AddNewLessonModal
            idPlanScheduled={params.scheduled}
            idLesson={params.idLesson}
            showAddExtraLessonModal={showAddExtraLessonModal}
            setShowAddExtraLessonModal={setShowAddExtraLessonModal}
            onSubmit={handleSaveAddLesson}
            errorAddScheduledLesson={errorAddScheduledLesson}
            successAddScheduledLesson={successAddScheduledLesson}
            scheduledPlanCoursesHours={scheduledPlanCoursesHours}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

ClassConfiguration.propTypes = {
  intl: intlShape,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  getScheduledLessonData: PropTypes.func.isRequired,
  manageScheduledLessonData: PropTypes.func.isRequired,
  lessonData: PropTypes.any.isRequired,
  editScheduledLessonResources: PropTypes.func.isRequired,
  deleteScheduledLessonResources: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  }).isRequired,
  changeScheduledLessonVisibility: PropTypes.func.isRequired,
  getScheduledLessonChat: PropTypes.func.isRequired,
  addScheduledLessonChatMessage: PropTypes.func.isRequired,
  canSendMessages: PropTypes.bool.isRequired,
  conversationsList: PropTypes.array.isRequired,
  messagesList: PropTypes.array.isRequired,
  downloadLessonResourseFile: PropTypes.func.isRequired,
  addScheduledLesson: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  errorAddScheduledLesson: PropTypes.string,
  successAddScheduledLesson: PropTypes.string,
  getScheduledPlanCoursesHours: PropTypes.func.isRequired,
  scheduledPlanCoursesHours: PropTypes.array.isRequired,
  mediaFile: PropTypes.any.isRequired,
  viewLessonResourseFile: PropTypes.func.isRequired,
  resetLessonData: PropTypes.func.isRequired,
};

ClassConfiguration.defaultProps = {
  intl: [],
  errorAddScheduledLesson: '',
  successAddScheduledLesson: '',
};

const mapStateToProps = ({ classConfiguration, app }) => ({
  ...classConfiguration,
  ...app,
});

export default compose(
  withRouter,
  injectIntl,
  connect(mapStateToProps, act.promisify({ ...classConfigurationActions })),
)(ClassConfiguration);
