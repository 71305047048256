/* eslint-disable array-callback-return */
/* eslint-disable max-lines */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-sequences */
/* eslint-disable react/jsx-fragments */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState, Fragment } from 'react';
import PropTypes, { array } from 'prop-types';
import moment from 'moment';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import { Row, Col } from 'react-bootstrap';
import { Tooltip } from 'react-tippy';

import { Button, Modal, Avatar, Loader, Select } from 'lec-ui';
// import Calendar from 'react-calendar';
import { getUserAvatarUrl } from 'app/appHelpers';

import placeholderSrc from 'app/images/placeholder-avatar.svg';
import imgPlaceholder from 'app/images/placeholder-createPlan.svg';

import PanelFilters from 'app/common/filters/panelFiltersContainer';

import * as act from 'app/actions';
import CardMonth from '../calendar/CardMonth';
import messages from './panelMessages';
import './PanelContainer.scss';
import 'react-calendar/dist/Calendar.css';

import * as myPlansActions from '../myPlans/myPlansActions';
import * as loginActions from '../login/loginActions';
import * as panelActions from './panelActions';
import DashboardCards from './DashboardCards';
import DashboardCardsStudent from './DashboardCardsStudent';
import CreateAnnualPlans from './CreateAnnualPlans';
import AvailableClassesProgress from './AvailableClassesProgress';

import RegisterSchoolForm from './RegisterSchoolForm';
import { set } from 'lodash';

const PanelContainer = (props) => {
  function toDayDate(date) {
    return moment(date).startOf('day').toDate();
  }

  const {
    intl,
    history,
    getFilterCatalogings,
    filters,
    getDashboardInfo,
    dashboardCards,
    getScheduledLessonsDates,
    scheduledLessonsDates,
    getMyPlans,
    myPlans,
    managePlanScheduledAnalytics,
    user,
    role,
    registerSchool,
    registerSchoolSuccess,
    registerStudentSchool,
    auth,
    getLatestAccesses,
    getAbilitiesHeatmap,
    latestAccesses,
    abilitiesHeatmap,
    hasError,
    abilitiesHeatmapFilter,
    getAbilitiesHeatmapFilter,
    segmentFilterType,
    isLoading,
    getSchoolYears,
    schoolYears,
  } = props;
  const [idSegment, setIdSegment] = useState(null);
  const [idComponent, setIdComponent] = useState(null);
  const [idPlan, setIdPlan] = useState(null);
  const [filterSegment, setFilterSegment] = useState([]);
  const [filterComponent, setFilterComponent] = useState([]);
  const [createPlan, setCreatePlan] = useState(false);
  const [currentDate, setCurrentDate] = useState(toDayDate());
  const [showModal, setShowModal] = useState(false);
  const [onlyRegisterSchool] = useState(true);
  const [accessToShow, setAccessToShow] = useState(7);
  const [abilitiesToShow, setAbilitiesToShow] = useState(20);
  const [loader, setLoader] = useState(false);
  const [abilitySelected, setAbilitySelected] = useState(null);
  const [heatmapAbilities, setHeatmapAbilities] = useState([]);

  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);

  const [schools, setSchools] = useState(null);
  const [selectedSchool, setSelectedSchool] = useState(null);

  function getColorForPercentage(percentage) {
    let hue = 0;
    let color = null;

    // if (percentage === 0) {
    //   hue = 0;
    //   color = `hsl(${hue}, 100%, 0%)`;
    // } else {
    hue = (percentage / 100) * 120;
    color = `hsl(${hue}, 100%, 50%)`;
    // }

    return color;
  }

  const handleLoadMore = (type) => {
    if (type === 'ability') {
      setAbilitiesToShow(abilitiesToShow + 20);
    } else {
      setAccessToShow(accessToShow + 7);
    }
  };

  function handleCreatePlan() {
    setCreatePlan(true);
    setIdSegment(null);
    setIdComponent(null);
    setIdPlan(null);
  }

  function handleMyPlans() {
    if (idSegment && idComponent) {
      history.push(`/my-plans?segment=${idSegment}&component=${idComponent}`);
    } else {
      history.push('my-plans');
    }
  }

  function handleMyClass() {
    history.push('my-class');
  }

  function handleMyLessons(idPlanScheduled) {
    managePlanScheduledAnalytics({ idPlanScheduled });
    history.push(`my-lessons?idPlanScheduled=${idPlanScheduled}`);
  }

  function selectDate(date) {
    setCurrentDate(date);
  }

  function nextDate() {
    const nextCurrentDate = moment(currentDate).add(1, 'month').toDate();
    selectDate(nextCurrentDate);
  }

  function previousDate() {
    const nextCurrentDate = moment(currentDate).subtract(1, 'month').toDate();
    selectDate(nextCurrentDate);
  }

  function handleAccessLessons(idPlanScheduled, idLesson, lessonIndex) {
    history.push(
      `/class-configuration?scheduled=${idPlanScheduled}&idLesson=${idLesson}&lessonIndex=${lessonIndex}`,
    );
  }

  useEffect(async () => {
    setLoader(true);
    await getDashboardInfo({
      year: selectedYear || new Date().getFullYear(),
    });
    if (role !== 4) {
      await getFilterCatalogings();
      // await getMyPlans({
      //   year: selectedYear || new Date().getFullYear(),
      // });
      // await getLatestAccesses({
      //   year: selectedYear || new Date().getFullYear(),
      // });
      await getAbilitiesHeatmapFilter();
      await getSchoolYears();

      // await getAbilitiesHeatmap();
    }

    if (user.idType >= 3 && !user.idSchool) {
      setShowModal(true);
    }

    setLoader(false);

    // clean up on unmount component
    return () => {
      setFilterSegment([]);
      setFilterComponent([]);
    };
  }, []);

  useEffect(() => {
    const currentMonthNumber = moment(currentDate).month() + 1;
    const oneMonthNumberFromNow = moment(currentDate).add(1, 'month').month() + 1;
    const twoMonthsNumberFromNow = moment(currentDate).add(2, 'month').month() + 1;

    getScheduledLessonsDates({
      months: [currentMonthNumber, oneMonthNumberFromNow, twoMonthsNumberFromNow],
    });
  }, [currentDate]);

  useEffect(() => {
    if (filterSegment.length <= 0 && segmentFilterType) {
      setFilterSegment(filters);
    }
    if (filterSegment.length > 0 && idSegment && !segmentFilterType) {
      setFilterComponent(filters);
    }
  }, [filters]);

  useEffect(async () => {
    setLoader(true);
    // if (idSegment && filterSegment.length > 0) {
    await getFilterCatalogings({ idCataloging: idSegment });

    setLoader(false);
    // }
  }, [idSegment, filterSegment]);

  useEffect(() => {
    if (createPlan && idComponent && idPlan) {
      const planSaved = myPlans.find(
        (el) =>
          el.idCataloging === idComponent && el.idParent === idSegment && el.idPlan === idPlan,
      );
      if (planSaved) {
        history.push(
          `/create-plan?planSaved=${planSaved.id}&segment=${planSaved.idParent}&component=${planSaved.idCataloging}`,
        );
      } else {
        history.push(`/create-plan?idPlan=${idPlan}&segment=${idSegment}&component=${idComponent}`);
      }
    } else if (!createPlan && idSegment && idComponent && idPlan) {
      handleMyPlans();
    }
  }, [createPlan, idComponent]);

  const oneMonthFromNow = moment(currentDate).add(1, 'month').toDate();
  const twoMonthsFromNow = moment(currentDate).add(2, 'month').toDate();

  function handleCloseModal() {
    setShowModal(false);
  }

  async function handleSubmitRegisterSchool(values) {
    await registerSchool(values);
    setShowModal(false);
  }

  function handleChangeFilterAbility(e) {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const { value } = selectedOption;
    setAbilitySelected(value);
  }

  function handlePlanValidation() {
    if (idSegment && idComponent) {
      history.push(`/plans-validation?segment=${idSegment}&component=${idComponent}`);
    } else {
      history.push('plans-validation');
    }
  }

  useEffect(() => {
    if (registerSchoolSuccess) {
      setShowModal(false);
    }
  }, [registerSchoolSuccess]);

  useEffect(async () => {
    if (!user.idSchool && user.idType > 3) {
      await registerStudentSchool();
      await auth();
    }
  }, [user.idType]);

  useEffect(() => {
    if (hasError) {
      setLoader(false);
    }
  }, [hasError]);

  useEffect(async () => {
    if (abilitySelected) {
      setLoader(true);
      setHeatmapAbilities([]);
      await getAbilitiesHeatmap({ idPlanScheduled: abilitySelected });
      setLoader(false);
    }
  }, [abilitySelected]);

  useEffect(() => {
    if (abilitiesHeatmap.length > 0) {
      setHeatmapAbilities(abilitiesHeatmap);
    }
  }, [abilitiesHeatmap]);

  // useEffect(() => {
  //   if (abilitiesHeatmapFilter.length > 0) {
  //     setAbilitySelected(abilitiesHeatmapFilter[0].value);
  //   }
  // }, [abilitiesHeatmapFilter]);

  useEffect(() => {
    if (schoolYears && schoolYears.length > 0) {
      const uniqueYears = schoolYears.map((year) => ({
        value: year,
        label: year,
      }));

      if (!uniqueYears.find((year) => parseInt(year.value) === new Date().getFullYear())) {
        uniqueYears.push({
          value: new Date().getFullYear().toString(),
          label: new Date().getFullYear().toString(),
        });
      }
      //reorder years
      uniqueYears.sort((a, b) => b.value - a.value);

      setYears(uniqueYears);
      setSelectedYear(selectedYear || uniqueYears[0].value);
    }
  }, [schoolYears]);

  useEffect(async () => {
    if (selectedYear) {
      setLoader(true);
      sessionStorage.setItem('selectedYear', selectedYear);

      if (role !== 4) {
        await getMyPlans({
          year: selectedYear,
        });

        await getLatestAccesses({
          year: selectedYear,
        });
      }
      await getDashboardInfo({
        year: selectedYear,
      });
      setLoader(false);
    }
  }, [selectedYear]);

  useEffect(() => {
    const year = sessionStorage.getItem('selectedYear');
    if (year) {
      setSelectedYear(year);
    }
    if (selectedYear) {
      sessionStorage.setItem('selectedYear', selectedYear);
    } else {
      sessionStorage.setItem('selectedYear', new Date().getFullYear().toString());
    }

    const school = sessionStorage.getItem('selectedSchool');
    if (school) {
      setSelectedSchool(school);
    }

    if (props.user.idSchools) {
      const uniqueSchools = props.user.idSchools.map((school) => ({
        value: school.id,
        label: school.name,
      }));

      setSchools(uniqueSchools);

      if (school) {
        setSelectedSchool(school);
        sessionStorage.setItem('selectedSchool', school);
      } else {
        setSelectedSchool(props.user.idSchools[0].id);
        sessionStorage.setItem('selectedSchool', props.user.idSchools[0].id);
      }
    } else if (props.user.idSchool) {
      setSelectedSchool(props.user.idSchool);
      sessionStorage.setItem('selectedSchool', props.user.idSchool);
    }
  }, []);

  async function handleChangeSelectedYear(selectedYearId) {
    setSelectedYear(selectedYearId);
  }

  async function handleSchoolChange(selectedSchoolId) {
    setSelectedSchool(selectedSchoolId);

    sessionStorage.setItem('selectedSchool', selectedSchoolId);
  }

  return (
    <Row>
      <Loader show={loader || isLoading} />
      <Col xs={12} md={12} lg={9} xl={9}>
        <PanelFilters
          idSegment={idSegment}
          idComponent={idComponent}
          filterSegment={filterSegment}
          filterComponent={filterComponent}
          setIdSegment={(value) => setIdSegment(value)}
          setIdComponent={(value) => setIdComponent(value)}
          setPlan={(value) => setIdPlan(value)}
          role={role}
          years={years}
          selectedYear={selectedYear}
          setSelectedYear={(value) => handleChangeSelectedYear(value)}
          schools={schools}
          selectedSchool={selectedSchool}
          setSelectedSchool={(value) => handleSchoolChange(value)}
        />
        {Object.keys(dashboardCards).length > 0 && !createPlan && role !== 4 && (
          <DashboardCards
            cards={dashboardCards}
            myPlans={() => handleMyPlans()}
            myClass={() => handleMyClass()}
            createPlan={() => handleCreatePlan()}
            planValidation={() => handlePlanValidation()}
            idType={user.idType}
          />
        )}

        {Object.keys(dashboardCards).length > 0 && !createPlan && role === 4 && (
          <DashboardCardsStudent cards={dashboardCards} myClass={() => handleMyClass()} />
        )}
        {role !== 4 && !createPlan && (
          <Row className="coverage-container">
            <Col xs={12} md={12} lg={6} xl={6}>
              <h4>{intl.formatMessage(messages.skillsCoverage)}</h4>

              <Select
                containerclassname="mt-0 mr-3 w-100"
                placeholder={intl.formatMessage(messages.selectPlanAndClass)}
                onChange={(e) => handleChangeFilterAbility(e)}
                options={abilitiesHeatmapFilter || []}
                value={abilitySelected || ''}
                nullable="true"
              />

              <div className="heatmap-container mt-3">
                {heatmapAbilities.length > 0
                  ? heatmapAbilities.slice(0, abilitiesToShow).map((item) => (
                      <Tooltip
                        className="lessonsTooltip"
                        position="bottom"
                        theme="light"
                        html={
                          item.description && <div className="description">{item.description}</div>
                        }
                      >
                        <div
                          className="heatmap-item"
                          style={{
                            backgroundColor: getColorForPercentage(
                              (item.lessonApplied / item.lessonsTotal) * 100,
                            ),
                          }}
                        >
                          <div
                            className="text"
                            style={{
                              color: getColorForPercentage(
                                (item.lessonApplied / item.lessonsTotal) * 100,
                              ),
                            }}
                          >
                            {item.idAbility}
                          </div>
                        </div>
                      </Tooltip>
                    ))
                  : abilitySelected &&
                    !loader && <div>{intl.formatMessage(messages.noAbilitiesFounded)}</div>}
              </div>
              {abilitiesToShow <= heatmapAbilities.length && (
                <Button className="btn loadmore" onClick={() => handleLoadMore('ability')}>
                  {intl.formatMessage(messages.loadMore)}
                </Button>
              )}
            </Col>
            <Col xs={12} md={12} lg={6} xl={6}>
              <h4>{intl.formatMessage(messages.latestAccesses)}</h4>
              {latestAccesses.length > 0 ? (
                latestAccesses.slice(0, accessToShow).map((item) => (
                  <Row className="students-block">
                    <Col xs={2} md={2} lg={2} xl={2} className="student-image">
                      <Avatar
                        src={getUserAvatarUrl(item.photo)}
                        placeholderSrc={placeholderSrc}
                        alt=""
                        fullSize
                      />
                    </Col>
                    <Col xs={6} md={6} lg={6} xl={6} className="student-data">
                      <div>
                        {item.fullname} - <span>{item.idUser}</span>
                      </div>
                      <div>{item.courseName}</div>
                    </Col>

                    <Col xs={4} md={4} lg={4} xl={4} className="student-hours">
                      <div>{item.lessonName}</div>
                      <div
                        className="btn-date"
                        onClick={() =>
                          handleAccessLessons(item.idPlanScheduled, item.idLesson, item.lessonIndex)
                        }
                      >
                        {moment(item.added).format('DD/MMM')}
                      </div>
                    </Col>
                  </Row>
                ))
              ) : (
                <div>{intl.formatMessage(messages.noAccessFounded)}</div>
              )}
              {accessToShow <= latestAccesses.length && (
                <Button className="btn loadmore" onClick={() => handleLoadMore('access')}>
                  {intl.formatMessage(messages.loadMore)}
                </Button>
              )}
            </Col>
          </Row>
        )}
        <Row>
          {role !== 4 ? (
            <Fragment>
              {!createPlan && (
                <div className="noPlansBlock">
                  <img src={imgPlaceholder} alt="placeholder" className="noPlansImage" />
                  {dashboardCards.plansCreated === 0 && (
                    <Fragment>
                      <h3>{intl.formatMessage(messages.noPlansCreated)}</h3>
                    </Fragment>
                  )}
                  <p>{intl.formatMessage(messages.createPlansText)}</p>
                  <Button className="btn" onClick={() => handleCreatePlan()}>
                    {intl.formatMessage(messages.createPlanButton)}
                  </Button>
                </div>
              )}
              {createPlan && (
                <CreateAnnualPlans
                  segments={filterSegment}
                  components={filterComponent}
                  setSegment={(value) => setIdSegment(value)}
                  setComponent={(value) => setIdComponent(value)}
                  setPlan={(value) => setIdPlan(value)}
                  segmentSelected={idSegment}
                  componentSelected={idComponent}
                />
              )}
            </Fragment>
          ) : (
            <Fragment>
              {dashboardCards &&
                dashboardCards.disciplinesProgress &&
                dashboardCards.disciplinesProgress.length > 0 && (
                  <AvailableClassesProgress
                    disciplinesProgress={dashboardCards && dashboardCards.disciplinesProgress}
                    myLessons={(value) => handleMyLessons(value)}
                  />
                )}
            </Fragment>
          )}
        </Row>
      </Col>
      <Col xs={12} md={12} lg={3} xl={3} className="calendar-container">
        <button type="button" className="calendar-navButton" onClick={() => previousDate()}>
          <i className="fa fa-angle-up" />
        </button>
        <CardMonth
          month={currentDate.getMonth() + 1}
          year={currentDate.getFullYear()}
          appointments={scheduledLessonsDates}
          onClickDate={selectDate}
        />
        <CardMonth
          month={oneMonthFromNow.getMonth() + 1}
          year={oneMonthFromNow.getFullYear()}
          appointments={scheduledLessonsDates}
          onClickDate={() => selectDate()}
        />
        <CardMonth
          month={twoMonthsFromNow.getMonth() + 1}
          year={twoMonthsFromNow.getFullYear()}
          appointments={scheduledLessonsDates}
          onClickDate={() => selectDate()}
        />

        <button type="button" className="calendar-navButton" onClick={() => nextDate()}>
          <i className="fa fa-angle-down" />
        </button>
      </Col>

      {!user.idSchool && user.idType <= 3 && (
        <Modal
          show={showModal}
          onClose={() => handleCloseModal()}
          large
          title={intl.formatMessage(messages.needRegisterSchoolTitle)}
        >
          <h6>{intl.formatMessage(messages.needRegisterSchool)}</h6>
          <br />
          <RegisterSchoolForm
            onSubmit={(e) => handleSubmitRegisterSchool(e)}
            onlyRegisterSchool={onlyRegisterSchool}
          />
        </Modal>
      )}
    </Row>
  );
};

PanelContainer.propTypes = {
  intl: intlShape,
  getFilterCatalogings: PropTypes.func.isRequired,
  getDashboardInfo: PropTypes.func.isRequired,
  getMyPlans: PropTypes.func.isRequired,
  getScheduledLessonsDates: PropTypes.func.isRequired,
  scheduledLessonsDates: PropTypes.arrayOf(PropTypes.any),
  filters: PropTypes.arrayOf(PropTypes.any),
  myPlans: PropTypes.arrayOf(PropTypes.any),
  dashboardCards: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    idSchool: PropTypes.number,
    idType: PropTypes.number,
  }).isRequired,
  role: PropTypes.number.isRequired,
  managePlanScheduledAnalytics: PropTypes.func.isRequired,
  registerSchool: PropTypes.func.isRequired,
  registerSchoolSuccess: PropTypes.bool.isRequired,
  registerStudentSchool: PropTypes.func.isRequired,
  auth: PropTypes.func.isRequired,
  getLatestAccesses: PropTypes.func.isRequired,
  getAbilitiesHeatmap: PropTypes.func.isRequired,
  latestAccesses: PropTypes.arrayOf(PropTypes.any).isRequired,
  abilitiesHeatmap: PropTypes.arrayOf(PropTypes.any).isRequired,
  hasError: PropTypes.bool.isRequired,
  abilitiesHeatmapFilter: PropTypes.arrayOf(PropTypes.any).isRequired,
  getAbilitiesHeatmapFilter: PropTypes.func.isRequired,
  segmentFilterType: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getSchoolYears: PropTypes.func.isRequired,
  schoolYears: PropTypes.arrayOf(PropTypes.any),
};

PanelContainer.defaultProps = {
  intl: [],
  filters: [],
  myPlans: [],
  dashboardCards: {},
  scheduledLessonsDates: [],
  schoolYears: [],
};

const mapStateToProps = ({ panel, myPlans, app }) => ({
  ...panel,
  ...myPlans,
  user: app.user,
  role: app.role,
});

export default compose(
  withRouter,
  injectIntl,
  connect(
    mapStateToProps,
    act.promisify(
      { ...myPlansActions, ...panelActions, ...loginActions },
      {
        include: [
          'getFilterCatalogings',
          'getDashboardInfo',
          'getMyPlans',
          'getScheduledLessonsDates',
          'registerStudentSchool',
          'registerSchool',
          'auth',
          'getLatestAccesses',
          'getAbilitiesHeatmap',
          'getAbilitiesHeatmapFilter',
          'getSchoolYears',
        ],
      },
    ),
  ),
)(PanelContainer);
