/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { injectIntl, intlShape } from 'react-intl';

import messages from '../myClassStudentsMessages';
import '../MyClassStudents.scss';

const Menu = (props) => {
  const { intl, onEdit } = props;

  return (
    <div className="card-menu">
      <i className="fas fa-ellipsis-v" />
      <div className="cm-floating">
        <span>
          <a className="cmf-view" onClick={onEdit}>
            <i className="ti-settings" />
            <div>{intl.formatMessage(messages.edit)}</div>
          </a>
        </span>
      </div>
    </div>
  );
};

Menu.propTypes = {
  intl: intlShape.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default compose(injectIntl)(Menu);
