/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-sequences */
/* eslint-disable react/jsx-fragments */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import queryString from 'query-string';
import { Row, Col } from 'react-bootstrap';

import { Loader } from 'lec-ui';

import * as act from 'app/actions';
import PanelFilters from 'app/common/filters/panelFiltersContainer';
import { set } from 'lodash';
import messages from './plansValidationMessages';

import * as plansValidationActions from './plansValidationActions';
import * as panelActions from '../panel/panelActions';

import PlansValidationCard from './PlansValidationCard';

const PlansValidationContainer = (props) => {
  const {
    intl,
    location,
    getFilterCatalogings,
    getPlansValidation,
    filters,
    plansValidation,
    history,
    isLoading,
    segmentFilterType,
    filterTeachers,
  } = props;
  const params = queryString.parse(location.search.replace('?', ''));
  const [idSegment, setIdSegment] = useState(null);
  const [idComponent, setIdComponent] = useState(null);
  const [idSegmentSelected, setIdSegmentSelected] = useState(null);
  const [idComponentSelected, setIdComponentSelected] = useState(null);

  const [filterSegment, setFilterSegment] = useState([]);
  const [filterComponent, setFilterComponent] = useState([]);

  const [idTeacher, setIdTeacher] = useState(null);
  const [idTeacherSelected, setIdTeacherSelected] = useState(null);
  const [filterTeacher, setFilterTeacher] = useState([]);

  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);

  const [schools, setSchools] = useState(null);
  const [selectedSchool, setSelectedSchool] = useState(null);

  useEffect(() => {
    (async () => {
      await getFilterCatalogings();
      await getPlansValidation();

      setIdSegmentSelected(params.segment);
      setIdComponentSelected(params.component);
    })();

    // clean up on unmount component
    return () => {
      setFilterSegment([]);
      setFilterComponent([]);
      setFilterTeacher([]);
    };
  }, []);

  useEffect(() => {
    if (filterSegment.length <= 0 && segmentFilterType) {
      setFilterSegment(filters);
    }
    if (filterSegment.length > 0 && idSegment && !segmentFilterType) {
      setFilterComponent(filters);
    }
  }, [filters]);

  useEffect(async () => {
    if (idSegment && filterSegment.length > 0) {
      await getFilterCatalogings({ idCataloging: idSegment });
    }
  }, [idSegment, filterSegment]);

  useEffect(async () => {
    // if (idComponent && idSegment) {
    await getPlansValidation({ idSegment, idComponent, idTeacher });
    // }
  }, [idSegment, idComponent]);

  useEffect(() => {
    if (filterTeachers.length > 0) {
      setFilterTeacher(filterTeachers);
    }
  }, [filterTeachers]);

  useEffect(() => {
    if (idTeacher && filterTeacher.length > 0 && idTeacherSelected !== idTeacher) {
      setIdTeacherSelected(idTeacher);
      getPlansValidation({ idSegment, idComponent, idTeacher });
    } else if (!idTeacher && idTeacherSelected !== null) {
      setIdTeacherSelected(null);
      getPlansValidation({ idSegment, idComponent, idTeacher });
    }
  }, [idTeacher, filterTeacher]);

  function handleViewPlan(planId, componentId, segmentId, idPlan) {
    history.push(
      `/view-plan?idPlan=${idPlan}&planSaved=${planId}&segment=${segmentId}&component=${componentId}`,
    );
  }

  useEffect(() => {
    if (selectedYear) {
      sessionStorage.setItem('selectedYear', selectedYear);
    }
  }, [selectedYear]);

  useEffect(() => {
    if (selectedSchool) {
      sessionStorage.setItem('selectedSchool', selectedSchool);
    }
  }, [selectedSchool]);

  useEffect(() => {
    const year = sessionStorage.getItem('selectedYear');

    const yearsArray = [
      {
        value: year,
        label: year,
      },
    ];
    setYears(yearsArray);

    if (year) {
      setSelectedYear(year);
    }

    const school = sessionStorage.getItem('selectedSchool');
    if (school) {
      setSelectedSchool(school);
    }

    if (props.user.idSchools) {
      const uniqueSchools = props.user.idSchools.map((school) => ({
        value: school.id,
        label: school.name,
      }));

      setSchools(uniqueSchools);
    }
  }, []);

  async function handleChangeSelectedYear(selectedYearId) {
    setSelectedYear(selectedYearId);
  }

  async function handleChangeSelectedSchool(selectedSchoolId) {
    setSelectedSchool(selectedSchoolId);
  }

  return (
    <Fragment>
      <Loader show={isLoading} />
      <Row>
        <Col>
          <PanelFilters
            idSegment={idSegment}
            idComponent={idComponent}
            filterSegment={filterSegment}
            filterComponent={filterComponent}
            setIdSegment={(value) => setIdSegment(value)}
            setIdComponent={(value) => setIdComponent(value)}
            idSegmentSelected={idSegmentSelected}
            idComponentSelected={idComponentSelected}
            idTeacher={idTeacher}
            filterTeacher={filterTeacher}
            setIdTeacher={(value) => setIdTeacher(value)}
            idTeacherSelected={idTeacherSelected}
            years={years}
            selectedYear={selectedYear}
            setSelectedYear={(value) => handleChangeSelectedYear(value)}
            disabledYear
            schools={schools}
            selectedSchool={selectedSchool}
            setSelectedSchool={(value) => handleChangeSelectedSchool(value)}
            disabledSchool
          />
          <div className="cp-container">
            <div>
              <h3 className="cp-title">{intl.formatMessage(messages.title)}</h3>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          {plansValidation && plansValidation.length > 0
            ? plansValidation.map((plan, idx) => (
                <PlansValidationCard
                  key={`${plan.id}-${idx}`}
                  plan={plan}
                  viewPlan={(planId, componentId, segmentId, idPlan) =>
                    handleViewPlan(planId, componentId, segmentId, idPlan)
                  }
                />
              ))
            : intl.formatMessage(messages.noPlansFound)}
        </Col>
      </Row>
    </Fragment>
  );
};

PlansValidationContainer.propTypes = {
  intl: intlShape,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  getFilterCatalogings: PropTypes.func.isRequired,
  getPlansValidation: PropTypes.func.isRequired,
  filters: PropTypes.any.isRequired,
  plansValidation: PropTypes.any.isRequired,
  isLoading: PropTypes.bool.isRequired,
  segmentFilterType: PropTypes.bool.isRequired,
  filterTeachers: PropTypes.any.isRequired,
};

PlansValidationContainer.defaultProps = {
  intl: [],
};

const mapStateToProps = ({ plansValidation, panel, app }) => ({
  ...plansValidation,
  ...panel,
  user: app.user,
});

export default compose(
  withRouter,
  injectIntl,
  connect(
    mapStateToProps,
    act.promisify(
      { ...plansValidationActions, ...panelActions },
      {
        include: ['getPlansValidation', 'getFilterCatalogings'],
      },
    ),
  ),
)(PlansValidationContainer);
